import React, { Component } from "react";
import TinyPhoto from "./../TinyPhoto/TinyPhoto";
import "./TinyGallery.scss";

function TinyGallery ({children}) {
  console.log("Start TinyGallery");
  
  var count = 0;

  React.Children.forEach(children, (child) => {
    if (child.type.name !== Photo.name) {
        count++;
    }
  });

  console.log("final count: " + count);

  if (count === 2) {
    return (
      <div className="TinyGallery">
        Return2
        {children}[0]
      </div>
   );
  }

  return (
     <div className="TinyGallery">
      ReturnDefault
       {children}
     </div>
  );
}

function Row () {
  return (
     <div className="Row">
       {children}
     </div>
  );
}

function Photo (props) {
  console.log("Start Photo");
  console.log(props);
  return (
       <TinyPhoto {...props} />
  );
}

export { TinyGallery, Row, Photo };