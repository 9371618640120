import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import { TinyGallery, Photo } from "../components/TinyGallery/TinyGallery";
import config from "../../data/SiteConfig";

class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`♡ ${config.siteTitle}`} />
          <div>
            <TinyGallery>
              <Photo src="/img/IMG_8199.PNG" />
              <Photo src="/img/IMG_8199.PNG" caption="123" />
            </TinyGallery>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;